const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    height: "100%",
    padding: "15px",
  },
  timesMainBox: {
    height: "20%",
    display: "grid",
    color: "white",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `
        'timeRow1' 
        'timeRow2'
      `,
  },
  timeRow1: {
    gridArea: "timeRow1",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `'timeBox1 timeBox2'`,
  },
  timeRow2: {
    gridArea: "timeRow2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr ",
    gridTemplateAreas: `'timeBox1 timeBox2'`,
  },
  timeBox1: {
    gridArea: "timeBox1",
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  timeBox2: {
    gridArea: "timeBox2",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "5px",
  },
  //////////////
  londonFixMainBox: {
    gridArea: "londonFixBox",
    height: "18%",
    paddingTop: "10px",
    // paddingLeft:"0.5em"
  },
  londonFixFontMainBox: {
    display: "grid",
    gridTemplateColumns: "0.7fr 1fr 1fr ",
    position: "relative",
    top: 10,
    gridTemplateAreas: `'londonFixFontOuterBox londonFixAm londonFixPm'`,
  },
  londonFixFontOuterBox: {
    gridArea: "londonFixFontOuterBox",
    width: "6rem",
    height: "1.5rem",
    borderRadius: "5px",
    px: "1vw",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFF",
    margin: "auto",
    boxShadow: "0 0 0 .5px #ccc",
    display: "flex",
  },

  londonFixAm: {
    gridArea: "londonFixAm",
    width: "2.2rem",
    height: "1.5rem",
    borderRadius: "5px",
    px: "1vw",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFF",

    margin: "auto",
    boxShadow: "0 0 0 .5px #ccc",
  },
  londonFixPm: {
    gridArea: "londonFixPm",
    width: "2.2rem",
    height: "1.5rem",
    borderRadius: "5px",
    px: "1vw",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFF",

    margin: "auto",
    boxShadow: "0 0 0 .5px #ccc",
  },

  londonFixValueBox: {
    display: "grid",
    columnGap: "5px",
    borderRadius: "5px",
    boxShadow: "0 0 0 .5px #ccc",
    padding: "1.2rem .8rem .8rem 0rem",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "0.7fr 1fr 1fr",
    gridTemplateAreas: `'londonFixGoldOzFont londonFixAmBox londonFixPmBox'`,
  },
  londonFixGoldOzFont: {
 
    gridArea: "londonFixGoldOzFont",
    display:"flex",
    alignItems:"flex-end",
    paddingLeft:"0.5em"

  },
  londonFixAmBox: {
    gridArea: "londonFixAmBox",
  },
  londonFixPmBox: {
    gridArea: "londonFixPmBox",
  },
  chartMainBox: {
    height: "40%",
    width: "100%",
  },
  usdMainBox: {
    height: "12%",
    width: "100%",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `
        'usdHeader' 
        'usdSubValues'
      `,
    // border: "1px solid #ccc",
    backgroundColor: "#FFFF",
    // boxShadow: "0 0 0 .5px #ccc",
    // padding: "10px",
    borderRadius: "5px",
    display: "grid",
    boxSizing: "border-box",
    textAlign: "center",
    // gap: "10px",
    marginTop: "15px",
  },
  usdHeader: {
    gridArea: "usdHeader",
    // border: "1px solid #ccc",
    boxShadow:
      " 1px 1px 15px rgba(255, 255, 255, 0.2),15px 15px 15px rgba(0, 0, 0, 0.1),inset -5px -5px 5px rgba(255, 255, 255, 0.2),inset 5px 5px 5px rgba(0, 0, 0, 0.1)",
    // box-shadow: 5px 10px #888888;
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  usdSubValues: {
    gridArea: `usdSubValues`,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateAreas: `'flag1Box flag2Box flag3Box flag4Box'`,
  },
  flag1Box: {
    gridArea: `flag1Box`,
    gap: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flag2Box: {
    gridArea: "flag2Box",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    justifyContent: "center",
  },
  flag3Box: {
    gridArea: "flag3Box",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    justifyContent: "center",
  },
  flag4Box: {
    gridArea: "flag4Box",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    justifyContent: "center",
  },
}));
