import React, { useEffect, useState } from "react";
import "./App.css";
import { Box, useMediaQuery, Typography } from "@mui/material";
import moment from "moment-timezone";
import ComputerScreenError from "../src/assets/computer-screen-error.png";
import SpotPage from "./pages/SpotPage";
import Charts from "./pages/Charts";
import Contact from "./pages/Contact";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import backGroundImg from "../src/assets/background-image.png";
import Marquee from "react-fast-marquee";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );
        // console.log(dubaiTime.format("H"));
        // if (dubaiTime.format("ddd") !== "Mon" && dubaiTime.format("H") < 2) {
        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        // const timeRemaining = duration - elapsedTime;
        if (elapsedTime <= 0) {
          // Timer completed
          clearInterval(timerId);
          console.log("Timer completed!");
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/qr");
    }
  }, [location.pathname]);

  const isMobileScreen = useMediaQuery("(max-width: 500px)");
  return (
    <>
      {isMobileScreen ? (
        <Box
          sx={{
            height: "100dvh",
            width: "100vw",
            backgroundImage: `url(${backGroundImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-280px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "200px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "50px",
                  // height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "red",
                  backgroundColor: "black",
                  color: "#FFFFFF",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  // padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay={1}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px", fontSize: "2.5em" }}>
                      Market closed! Opens in{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}

          <Routes>
            <Route
              path="/qr"
              exact
              element={<SpotPage setIsMarketOpen={setIsMarketOpen} />}
            />
            <Route path="/chart" exact element={<Charts />} />
            <Route path="/contact" exact element={<Contact />} />
          </Routes>
          <Footer />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
            background: "#FFFFFF",
          }}
        >
          <img
            src={ComputerScreenError}
            alt="Image"
            style={{ width: "50vw" }}
          />
          <Typography
            sx={{
              color: "#E51E31",
              fontSize: "1.3em",
              marginTop: 2,
              marginRight: 3,
              marginLeft: 3,
              textAlign: "center",
              fontFamily: "Calibri",
              fontWeight: "bold",
            }}
          >
            Computer Preview is not available; please open in Mobile.
          </Typography>
        </Box>
      )}
    </>
  );
}

export default App;
