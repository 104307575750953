const { makeStyles } = require("@mui/styles");
const backGroundImg = require("../assets/background-image.png");
export const useStyles = makeStyles({
  //Footer section
  footerContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#2B2937",
    color: "#FFFFFF",
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    height: "9.5%",
  },

  footerTexts: {
    textDecoration: "none",
    fontSize: "1.5em",
    color: "#FFFFFF",
    fontFamily: "Calibri",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  },
});
