import { Box, Typography } from "@mui/material";
import { useStyles } from "../styles/Charts";
import uaeLogo from "../assets/uaeLogo.png";
import indianLogo from "../assets/indianLogo.png";
import londonLogo from "../assets/londonLogo.png";
import newyorkLogo from "../assets/newyorkLogo.png";
import newYorkFlag from "../assets/NewYork_Flag.png";
import USAFlag from "../assets/USA_Flag.png";

import { useEffect, useState } from "react";
import moment from "moment-timezone";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import TradingViewWidget from "../components/chart/TradingViewWidget";
import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

export default function Charts() {
  const classes = useStyles();
  const [londonFixValue, setLondonFixValue] = useState();
  const [timeZones, setTimeZones] = useState({
    uaeTime: moment(Date.now()).tz("Asia/Dubai").format("hh:mm A"),
    indianTime: moment(Date.now()).tz("Asia/Kolkata").format("hh:mm A"),
    newYorkTime: moment(Date.now()).tz("America/New_York").format("hh:mm A"),
    londonTime: moment(Date.now()).tz("Europe/London").format("hh:mm A"),
  });

  const updateTime = () => {
    setTimeZones({
      ...timeZones,
      uaeTime: moment(Date.now()).tz("Asia/Dubai").format("hh:mm A"),
      indianTime: moment(Date.now()).tz("Asia/Kolkata").format("hh:mm A"),
      newYorkTime: moment(Date.now()).tz("America/New_York").format("hh:mm A"),
      londonTime: moment(Date.now()).tz("Europe/London").format("hh:mm A"),
    });
  };

  setInterval(updateTime, 1000);

  useEffect(() => {
    londonFixVal();
  }, [londonFixValue?.amValue]);

  const londonFixVal = async () => {
    let { data } = await axios.get(`${host}/userFindLondonFixValue/${adminId}`);
    setLondonFixValue(data);
  };

  const api_url = `${host}/getConversionValue/USD`;

  const [aed, setAed] = useState(0);
  const [inr, setInr] = useState(0);
  const [gbp, setGbp] = useState(0);
  const [usd, setUsd] = useState(0);
  const getCurrencyRate = async () => {
    try {
      const response = await axios.get(api_url).then((response) => {
        const data = response?.data;
        // console.log(data.conversion_rates);
        setAed(data?.conversion_rates.AED);
        setGbp(data?.conversion_rates.GBP);
        setUsd(data?.conversion_rates.USD);
        setInr(data?.conversion_rates.INR);
      });
    } catch (error) {
      console.log("Failed to retrieve currency data.", error);
    }
  };
  useEffect(() => {
    getCurrencyRate();
  }, []);

  return (
    <Box className={classes.background}>
      <Box className={classes.timesMainBox}>
        <Box className={classes.timeRow1}>
          <Box className={classes.timeBox1}>
            <Box>
              <img
                src={uaeLogo}
                alt="us"
                style={{ width: "2.5rem", height: "2.5rem" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "0.5",
                }}
              >
                UAE
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                {timeZones.uaeTime}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.timeBox2}>
            <Box>
              <img
                src={londonLogo}
                alt="us"
                style={{ width: "2.5rem", height: "2.5rem" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "0.5",
                }}
              >
                LONDON
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                {timeZones.londonTime}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.timeRow2}>
          <Box className={classes.timeBox1}>
            <Box>
              <img
                src={indianLogo}
                alt="us"
                style={{ width: "2.5rem", height: "2.5rem" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "0.5",
                }}
              >
                INDIA
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                {timeZones.indianTime}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.timeBox2}>
            <Box>
              <img
                src={newyorkLogo}
                alt="us"
                style={{ width: "2.5rem", height: "2.5rem" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "0.5",
                }}
              >
                NEW YORK
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                {timeZones.newYorkTime}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.londonFixMainBox}>
        {/* <Box sx={{ width: "100%" }}> */}
        <Box className={classes.londonFixFontMainBox}>
          <Box className={classes.londonFixFontOuterBox}>
            <Typography
              sx={{
                fontSize: ".8rem",
                textAlign: "center",
                color: "#333333",
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "#333333",
              }}
            >
              LONDON FIX
            </Typography>
          </Box>

          <Box className={classes.londonFixAm}>
            <Typography
              sx={{
                fontSize: ".8rem",
                textAlign: "center",
                color: "#333333",
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "#333333",
              }}
            >
              AM
            </Typography>
          </Box>
          <Box className={classes.londonFixPm}>
            <Typography
              sx={{
                fontSize: ".8rem",
                textAlign: "center",
                color: "#333333",
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "#333333",
              }}
            >
              PM
            </Typography>
            {/* </Box> */}
          </Box>
        </Box>

        <Box className={classes.londonFixValueBox}>
          <Box className={classes.londonFixGoldOzFont}>
            <Typography
              sx={{
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                color: "#FFFF",
                alignItems: "flex-start",
                whiteSpace: "nowrap",
                fontWeight: 600,
              }}
            >
              GOLD Oz
            </Typography>
          </Box>
          <Box className={classes.londonFixAmBox}>
            <Box
              sx={{
                boxShadow: "0 0 0 .5px #ccc",
                borderRadius: "5px",
                color: "#FFFF",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  borderBottom: "1px solid #838383",
                  background: "#FFFFFF",
                  height: "45%",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px 5px 0 0",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "0.8rem",
                    marginRight: ".5vw",
                    color: "#333333",
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                  }}
                >
                  {londonFixValue
                    ? moment(new Date(londonFixValue?.date)).format("dddd")
                    : ""}
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    color: "#333333",
                    whiteSpace: "nowrap",
                  }}
                >
                  {londonFixValue
                    ? moment(new Date(londonFixValue?.date)).format("MMM: DD")
                    : ""}
                </span>
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "55%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0.5em 0",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                {londonFixValue ? londonFixValue?.amValue.toFixed(2) : 0}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.londonFixPmBox}>
            <Box
              sx={{
                boxShadow: "0 0 0 .5px #ccc",
                borderRadius: "5px",
                color: "#FFFF",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  borderBottom: "1px solid #838383",
                  background: "#FFFFFF",
                  height: "45%",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px 5px 0 0",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "0.8rem",
                    marginRight: ".5vw",
                    color: "#333333",
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                  }}
                >
                  {londonFixValue
                    ? moment(new Date(londonFixValue?.date)).format("dddd")
                    : ""}
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    color: "#333333",
                    whiteSpace: "nowrap",
                  }}
                >
                  {londonFixValue
                    ? moment(new Date(londonFixValue?.date)).format("MMM: DD")
                    : ""}
                </span>
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  height: "55%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0.5em 0",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                {londonFixValue?.pmValue
                  ? londonFixValue?.pmValue.toFixed(2)
                  : 0}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.chartMainBox}>
        <TradingViewWidget />
      </Box>

      <Box className={classes.usdMainBox}>
        <Box className={classes.usdHeader}>
          <img
            src={USAFlag}
            alt="us"
            style={{ width: "2rem", height: "2rem" }}
          />

          <KeyboardDoubleArrowDownIcon
            sx={{ fontWeight: "bold", fontSize: "2.5rem" }}
          />
        </Box>
        <Box className={classes.usdSubValues}>
          <Box className={classes.flag1Box}>
            {" "}
            <img
              src={uaeLogo}
              alt="us"
              style={{ width: "4.2vw", height: "4.2vw" }}
            />
            <Typography
              sx={{
                fontSize: "4vw",
                color: "Black",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {aed.toFixed(2)}
            </Typography>
          </Box>
          <Box className={classes.flag2Box}>
            {" "}
            <img
              src={indianLogo}
              alt="us"
              style={{ width: "4.2vw", height: "4.2vw" }}
            />
            <Typography
              sx={{
                fontSize: "4vw",
                color: "Black",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {inr.toFixed(2)}
            </Typography>
          </Box>
          <Box className={classes.flag3Box}>
            {" "}
            <img
              src={newYorkFlag}
              alt="us"
              style={{ width: "4.2vw", height: "4.5vw" }}
            />
            <Typography
              sx={{
                fontSize: "4vw",
                color: "Black",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {usd.toFixed(2)}
            </Typography>
          </Box>
          <Box className={classes.flag4Box}>
            {" "}
            <img
              src={londonLogo}
              alt="us"
              style={{ width: "4.2vw", height: "4.2vw" }}
            />
            <Typography
              sx={{
                fontSize: "4vw",
                color: "Black",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {gbp.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
