import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "../styles/Footer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation()
  const [activeBtn, setActiveBtn] = useState("Spot");
  const classes = useStyles();

  useEffect(()=>{
    if(location.pathname==="/qr"){
      setActiveBtn("Spot")
    }
    else if(location.pathname==="/chart"){
      setActiveBtn("Chart")
    }
    else{
      setActiveBtn("Contact")
    }
  },[])

 

  return (
    <>
      <Box className={classes.footerContainer} component="section">
        <Link
          to="/contact"
          className={classes.footerTexts}
          onClick={() => setActiveBtn("Contact")}
        >
          <AddIcCallIcon
            sx={{
              fontSize: "4vh",
              color: activeBtn === "Contact" ? "#FFFFFF" : "#6D7899",
            }}
          />
          <Typography
            sx={{
              cursor: "pointer",
              fontSize: "1.5vh",
              color: activeBtn === "Contact" ? "#FFFFFF" : "#6D7899",
            }}
          >
            Contact
          </Typography>
        </Link>

        <Link
          to="/qr"
          className={classes.footerTexts}
          onClick={() => setActiveBtn("Spot")}
        >
          <WorkOutlineIcon
            sx={{
              fontSize: "4vh",
              color: activeBtn === "Spot" ? "#FFFFFF" : "#6D7899",
            }}
          />
          <Typography
            sx={{
              cursor: "pointer",
              fontSize: "1.5vh",
              color: activeBtn === "Spot" ? "#FFFFFF" : "#6D7899",
            }}
          >
            Spot
          </Typography>
        </Link>
        <Link
          to="/chart"
          className={classes.footerTexts}
          onClick={() => setActiveBtn("Chart")}
        >
          <ShowChartIcon
            sx={{
              fontSize: "4vh",
              color: activeBtn === "Chart" ? "#FFFFFF" : "#6D7899",
            }}
          />
          <Typography
            sx={{
              cursor: "pointer",
              fontSize: "1.5vh",
              color: activeBtn === "Chart" ? "#FFFFFF" : "#6D7899",
            
            }}
          >
            Chart
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default Footer;
