// TradingViewWidget.jsx
import React, { useEffect, useRef } from "react";
function TradingViewWidget() {
  const scriptLoaded = useRef(false);
  const contariner = useRef();
  useEffect(() => {
    if (!scriptLoaded.current) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "symbols": [
            [
              "TVC:GOLD|12M"
            ]
          ],
          "chartOnly": true,
          "width": "100%",
          "height": "100%",
          "locale": "in",
          "colorTheme": "dark",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "gridLineColor": "rgba(255, 255, 255, 0.06)",
          "backgroundColor": "rgba(74, 20, 140, 0)",
          "lineWidth": 2,
          "lineType": 0,
          "dateRanges": [
            "12m|1D"
          ],
          "lineColor": "rgba(255, 255, 255, 1)"
        }`;
      scriptLoaded.current = true; // Set the flag to indicate script is loaded
      contariner.current.appendChild(script);
    }
  }, []);
  return (
    <div className="tradingview-widget-container" ref={contariner}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}
export default TradingViewWidget;
