import { Box, Typography } from "@mui/material";
import { useStyles } from "../styles/Contact";
import { useEffect, useState } from "react";
import BullionStatsLogo from "../assets/bullionLogo.png";
import call from "../assets/call.png";
import whatsapp from "../assets/whatsapp.png";
import mail from "../assets/mail.png";
import address from "../assets/address.png";
import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

function Contact() {
  const classes = useStyles();
  const [profile, setProfile] = useState({});

  const GetProfile = async () => {
    try {
      const response = await axios.get(
        `${host}/getAdminProfile/${adminId}`
      );
      setProfile(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  return (
    <Box className={classes.background} component="section">
      <Box className={classes.logoSection} component="section">
        <Box className={classes.logoWithContent} component="article">
          <img
            style={{
              height: "30vw",
              padding: "0.5em 0",
              width: "30vw",
            }}
            src={BullionStatsLogo}
            alt="Bullion_Stats_Logo"
          />
          <Typography
            sx={{
              marginTop: "0px",
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: "2vh",
            }}
          >
            Customer Support
          </Typography>
          <Typography
            sx={{ fontFamily: "Roboto", fontWeight: 400, fontSize: "1.6vh" }}
          >
            24 / 7 support
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contactSection}>
        <Box className={classes.row1}>
          <Box className={classes.column1}>
            <a href={`tel:${profile.phone}`} target="_blank">
              <img
                style={{
                  padding: "0.5em 0",
                  width: "14vw",
                }}
                src={call}
                alt="CallIcon"
              />
            </a>

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "2vh",
                color: "#FFFFFF",
              }}
            >
              Call us
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "1.3vh",
                color: "#6D7590",
              }}
            >
              Talk to our executives
            </Typography>
          </Box>
          <Box className={classes.column2}>
            <a href={`https://wa.me/+91${profile.whatsapp}`} target="_blank">
              <img
                style={{
                  padding: "0.5em 0",
                  width: "14vw",
                }}
                src={whatsapp}
                alt="CallIcon"
              />
            </a>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "2vh",
                color: "#FFFFFF",
              }}
            >
              Whatsapp
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "1.3vh",
                color: "#6D7590",
              }}
            >
              Chat with us
            </Typography>
          </Box>
        </Box>
        <Box className={classes.row2}>
          <Box className={classes.column1}>
            <a href={`mailto:${profile.email}`} target="_blank">
              <img
                style={{
                  padding: "0.5em 0",
                  width: "14vw",
                }}
                src={mail}
                alt="CallIcon"
              />
            </a>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "2vh",
                color: "#FFFFFF",
              }}
            >
              Mail
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "1.3vh",
                color: "#6D7590",
              }}
            >
              Drop us a line
            </Typography>
          </Box>
          <Box className={classes.column2}>
            <a
              href={`http://maps.google.com/?q=${profile.address}`}
              target="_blank"
            >
              <img
                style={{
                  padding: "0.5em 0",
                  width: "14vw",
                }}
                src={address}
                alt="CallIcon"
              />
            </a>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "2vh",
                color: "#FFFFFF",
              }}
            >
              Our Address
            </Typography>

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "1.3vh",
                color: "#6D7590",
              }}
            >
              Reach us at
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.chatSection}></Box>
    </Box>
  );
}

export default Contact;
