const { makeStyles } = require("@mui/styles");
const backGroundImg = require("../assets/background-image.png");
export const useStyles = makeStyles({
  //Spot page
  spotPage: {
    height: "100%",
  },

  //Spot page logo & date
  spotPageLogoSection: {
    height: "10%",
    display: "flex",
    padding: "1em 1em 0 1em",
  },
  spotPageLogoSectionLogo: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    marginLeft: "0.2em",
  },
  spotPageLogoSectionDate: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  //Spot page spotrate container
  spotPageSpotRateContainer: {
    height: "28%",
    width: "100%",
    padding: "0.8em 1em",
  },
  spotPageSpotRateSection: {
    backgroundColor: "#FFFFFF",
    height: "100%",
    width: "100%",
    borderRadius: "10px 10px 0 0",

    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  spotPageSpotRateSectionSpotRateHeader: {
    borderBottom: "1px solid #F4F4F4",
    boxShadow: "0 4px 6px -6px #000000",
    height: "30%",
  },
  spotPageSpotRateSectionSpotRateHeaderContents: {
    display: "flex",
    padding: "1em 0",
  },

  spotPageSpotRateSectionSpotRateBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  spotPageSpotRateSectionSpotRateGold: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0.5em",
  },
  spotPageSpotRateSectionSpotRateSilver: {
    display: "flex",
    alignItems: "center",
    paddingTop: "-1em",
    paddingBottom: "1em",
  },

  //Spot page commodity container
  spotPageCommodityContainer: {
    height: "24%",
    padding: "0 1em",
    width: "100vw",
    overflowX: "hidden",
  },

  commodityTable: {
    display: "grid",
    // gridTemplateColumns: "1fr",
    gridTemplateRows: "0.8fr 4fr",
    gridTemplateAreas: `
        'commodityTableHeader' 
        'commodityTableContent'
      `,
    height: "100%",
    width: "100%",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  commodityTableHeader: {
    gridArea: "commodityTableHeader",
    backgroundColor: "#FFFFFF",
    flex: 1,
    display: "flex",
    paddingLeft: "1em",
    paddingLeft: window.innerWidth < 380 ? "0.7em" : "1em",
    // position: "fixed",
    // left: "1em",
    // right: "1em",
    height: "3.5vh",
    zIndex: 99,
  },

  commodityTableContent: {
    flex: 1,
    gridArea: "commodityTableContent",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowX: "hidden",
  },
  commodityTableRowColumn: {
    flex: 1,
    display: "flex",
    color: "#FFFFFF",
    justifyContent: "flex-start",
    height: "100%",
  },
  commodityTableRow: {
    display: "flex",
  },

  //Spot page pamb bar section
  spotPagePambBarContainer: {
    height: "23%",
    padding: "0 1em",
    width: "100vw",
  },

  pambBarTable: {
    display: "grid",
    // gridTemplateColumns: "1fr",
    gridTemplateRows: "0.8fr 4fr",
    gridTemplateAreas: `
        'pambBarTableHeader' 
        'pambBarTableContent'
      `,
    height: "100%",
    width: "100%",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pambBarTableHeader: {
    gridArea: "pambBarTableHeader",
    backgroundColor: "#FFFFFF",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    // position: "fixed",
    // left: "1em",
    // right: "1em",
    height: "3.4vh",
    zIndex: 99,
  },

  pambBarTableContent: {
    gridArea: "pambBarTableContent",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowX: "hidden",
  },
  pambBarTableRowColumn: {
    flex: 1,
    width: "33%",
    display: "flex",
    color: "#FFFFFF",
    justifyContent: "flex-start",
    border: "1ps solid white",
  },
  pambBarTableRow: {
    display: "flex",
    justifyContent: "space-between",
  },
});
