const { makeStyles } = require("@mui/styles");

exports.useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    height: "100%",
  },
  logoSection: {
    width: "100%",
    height: "30%",
    color: "#ffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoWithContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  contactSection: {
    width: "100%",
    height: "45%",
    padding: "1rem 2rem 1rem 2rem",
    display: "grid",
    flexGrow: 1,
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `
    'row1'
    'row2'
    `,
    rowGap: "1rem",
  },
  row1: {
    gridArea: "row1",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `'column1 column2'`,
    columnGap: "1rem",
  },
  row2: {
    gridArea: "row2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `"column1 column2"`,
    columnGap: "1rem",
  },
  column1: {
    padding: "1rem",
    gridArea: "column1",
    backgroundColor: "#212F43",
    borderRadius: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  column2: {
    padding: "1rem",
    gridArea: "column2",
    backgroundColor: "#212F43",
    borderRadius: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chatSection: {
    width: "100%",
    height: "25%",
    color: "#fff",
  },
}));
